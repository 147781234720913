import React from 'react';
import './Temp.css'; // Import CSS file for styling

function Temp() {
  return (
    <div className="construction-message">
      <p className='first'>* The site is under construction *</p>
      <p className='second'>* The site is under construction *</p>
     
    </div>
  );
}

export default Temp;
