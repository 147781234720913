import React from 'react'
// import Blogs from '../components/Blogs/Blogs'

const Blogs = () => {
  document.title = 'IQApex Labs - Blogs'
  
  return (
    <div>
      {/* <Blogs /> */}
    </div>
  )
}

export default Blogs